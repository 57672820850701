<script>
import { PlanEditMode } from '@stellacontrol/planner'

export default {
  props: {
    // Plan renderer
    renderer: {
    },
    // Plan layout to edit
    layout: {
    },
    // Floor to show
    floor: {
    }
  },

  computed: {
    /**
     * Current edit mode of the plan
     * @type {PlanEditMode}
     */
    editMode () {
      return this.renderer?.editMode
    },

    /**
     * Label representing the current edit mode of the plan
     * @type {String}
     */
    editModeLabel () {
      const { editMode, renderer } = this
      const { itemToAdd } = renderer
      let before
      let after

      switch (editMode) {
        case PlanEditMode.AddingItems:
          if (itemToAdd) {
            const label = itemToAdd.labelLong || itemToAdd.label
            before = `Click to add ${label} to the plan. Hold <b>CTRL</b> and click to add multiple ${label}s.`
            after = 'Press CTRL+Z to undo'
          }
          break

        case PlanEditMode.DrawingLine:
          before = 'Click the line points. Finish with <b>double-click</b> or <b>ENTER</b>. Press <b>ESC</b> to cancel'
          after = '<b>Right-click</b> or press CTRL+Z to cancel the last point'
          break

        case PlanEditMode.DrawingPolygon:
          before = 'Click the polygon points. Click on the first point to close the polygon. Press <b>ESC</b> to cancel'
          after = '<b>Right-click</b> or press CTRL+Z to cancel the last point'
          break

        case PlanEditMode.DrawingWalls:
          before = 'Click the wall points. Click on the first point or press <b>ENTER</b> to finish the wall. Press <b>ESC</b> to cancel'
          after = 'Press CTRL+Z to undo'
          break

        case PlanEditMode.EditingWalls:
          before = 'Click and drag the wall points. Press <b>ESC</b> to finish'
          break

        case PlanEditMode.DrawingMapScale:
          before = 'Click two points indicating the distance of one meter. Press <b>ESC</b> to cancel'
          break

        case PlanEditMode.DrawingRuler:
          before = 'Click two points to measure the distance between them. Press <b>ESC</b> to cancel'
          break

        case PlanEditMode.SelectingTransparentColors:
          before = 'Click on colored areas to make them transparent. Press <b>ENTER</b> to finish, <b>ESC</b> to cancel'
          after = 'Press CTRL+Z to undo'
          break

        case PlanEditMode.DrawingCable:
          before = 'Click to guide the cable. Click the destination port to finish. <b>ESC</b> to cancel the entire cable.'
          after = '<b>Right-click</b> or press CTRL+Z to cancel the last point'
          break
      }

      return before || after
        ? { before, after }
        : null
    },

    // Determines whether FINISH button should be displayed for the current edit mode
    isFinishActionVisible () {
      const { editMode } = this

      const hasFinish = [
        PlanEditMode.SelectingTransparentColors
      ]

      return hasFinish.includes(editMode)
    },

    // Determines whether CANCEL button should be displayed for the current edit mode
    isCancelActionVisible () {
      const { editMode } = this
      const noCancel = []
      return !noCancel.includes(editMode)
    }
  },

  methods: {
    // Cancels the ongoing editing
    async cancelEditing () {
      const { renderer } = this
      await renderer.cancelEditing()
      await renderer.deselect()
    },

    // Finishes the ongoing editing
    async finishEditing () {
      const { renderer } = this
      await renderer.finishEditing()
      await renderer.deselect()
    }
  }
}
</script>

<template>
  <div class="edit-mode q-pl-md q-pt-sm q-pr-md q-pb-sm bg-green-8 text-white row items-center"
    v-if="editModeLabel">
    <q-icon name="info" size="sm" class="q-mr-xs"></q-icon>
    <span v-html="editModeLabel.before" class="q-mr-lg">
    </span>
    <q-btn v-if="isFinishActionVisible" label="Finish" dense flat @click="finishEditing()"
      class="bg-green-3 text-green-9 q-mr-sm">
    </q-btn>
    <q-btn v-if="isCancelActionVisible" label="Cancel" dense flat @click="cancelEditing()"
      class="bg-green-3 text-red-7">
    </q-btn>
    <span v-html="editModeLabel.after" class="q-ml-lg">
    </span>
  </div>
</template>

<style scoped lang="scss">
.edit-mode {
  flex: 1;
}
</style>
