/**
 * Actions which user can execute on the plan
 */
export const PlanActions = {
  // Empty action, useful for testing etc.
  Empty: 'empty',
  // Selects the items om the plan
  SelectItems: 'select-items',
  // Select all items
  SelectAllItems: 'select-all-items',
  // Adds an item to the plan
  AddItem: 'add-item',
  // Clears the entire floor
  ClearFloor: 'clear-floor',
  // Copy items
  CopyItems: 'copy-items',
  // Duplicate items
  DuplicateItems: 'duplicate-items',
  // Move items
  MoveItems: 'move-items',
  // Pastes items
  PasteItems: 'paste-items',
  // Remove items
  RemoveItems: 'remove-items',
  // Remove item point,
  RemovePoint: 'remove-point',
  // Resize items
  ResizeItems: 'resize-items',
  // Rotate items
  RotateItems: 'rotate-items',
  // Show help
  ShowHelp: 'show-help',
  // Sets background image
  SetBackgroundImage: 'set-background-image',
  // Clears background image
  ClearBackgroundImage: 'clear-background-image',
  // Sets the scaling of the equipment on the plan
  SetEquipmentScale: 'set-equipment-scale',
  // Sets the floor plan dimensions
  SetPlanSize: 'set-plan-size',
  // Sets the margin around the floor plan
  SetPlanMargin: 'set-plan-margin',
  // Sets the zoom level of the floor plan
  SetPlanZoom: 'set-plan-zoom',
  // Sets the scale of the floor map
  SetMapScale: 'set-map-scale',
  // Starts/ends setting the scale of the floor map by pointing it on the map
  DrawMapScale: 'draw-map-scale',
  // Toggles background image visibility
  ToggleBackgroundImage: 'toggle-background-image',
  // Toggles cable lengths visibility
  ToggleCableLengths: 'toggle-cable-lengths',
  // Sets the default cable type for the plan
  SetDefaultCableType: 'set-default-cable-type',
  // Toggles background grid visibility
  ToggleGrid: 'toggle-grid',
  // Starts/stops selection of transparent colors for the background image
  SetBackgroundTransparency: 'set-background-transparency',
  // Clears the transparent colors for the background image
  ClearBackgroundTransparency: 'clear-background-transparency',
  // Cancels editing the transparent colors
  CancelBackgroundTransparency: 'cancel-background-transparency',
  // Toggles grid size
  SetGridSize: 'set-grid-size',
  // Changes item properties
  SetItemProperties: 'set-item-properties',
  // Bring the item to the top
  BringToTop: 'bring-to-top',
  // Bring the item to the bottom
  BringToBottom: 'bring-to-bottom',
  // Toggles walls and yards lock
  LockWalls: 'lock-walls',
  // Clears walls and yards
  ClearWalls: 'clear-walls',
  // Sets the global radiation strength for all antennae
  SetRadiationStrength: 'set-radiation-strength',
  // Adds new floor to the plan
  AddFloor: 'add-floor',
  // Removes the floor from the plan
  RemoveFloor: 'remove-floor',
  // Sets the floor label
  SetFloorLabel: 'set-floor-label',
  // Sets the floor height
  SetFloorHeight: 'set-floor-height',
  // Marks the floor as selected on the cross-section view
  SelectFloor: 'select-floor',
  // Toggles equipment tag visibility
  ToggleTags: 'toggle-tags',
  // Toggles riser plugs and cables visibility
  ToggleRisers: 'toggle-risers',
  // Downloads a screenshot of the floor
  DownloadFloorImage: 'download-floor-image',
  // Display plan item details
  ShowItemDetails: 'show-item-details',
  // Merges risers
  MergeRisers: 'merge-risers',
  // Splits a riser
  SplitRiser: 'split-riser',
  // Moves cable into its own riser
  MoveToOwnRiser: 'move-to-own-riser',
  // Saves a snapshot of a plan
  SavePlanSnapshot: 'save-plan-snapshot',
  // Deletes a snapshot of a plan
  DeletePlanSnapshot: 'delete-plan-snapshot',
  // Restores a snapshot of a plan into the current plan
  RestorePlanSnapshot: 'restore-plan-snapshot'
}

/**
 * Action which user can execute on the plan
 */
export class PlanAction {
  constructor (data = {}) {
    this.items = (data.items || []).filter(i => i)
    this.data = data
    this.args = data.args
  }

  /**
   * Action name.
   * Must be implemented in descendants.
   * @type {String}
   */
  static get action () {
    throw new Error('Not implemented')
  }

  /**
   * Plan items to execute the action on
   * @type {Array[PlanItem]}
   */
  items

  /**
   * Custom data passed when action was created.
   * Can be used to implement dynamic labels etc.
   * as it's used in all the getters below as a source of default values.
   * @type {Object}
   */
  data

  /**
   * Fixed arguments tu use when executing the action, passed when action was created.
   * Runtime arguments can be passed when calling {@link execute}
   * @type {Object}
   */
  args

  /**
  * Action name
  * @type {String}
  */
  get name () {
    return this.constructor.action
  }

  /**
   * Returns the number of items to act on
   * @type {Number}
   */
  get count () {
    return this.items.length || 0
  }

  /**
   * Indicates that there are some items to act on
   * @type {Boolean}
   */
  get hasItems () {
    return this.count > 0
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return Boolean(this.data?.requiresItems)
  }

  /**
   * Single item to act on
   * @type {PlanItem}
   */
  get item () {
    return this.items[0]
  }

  /**
   * Indicates that the action will be executed on a single item
   * @type {Boolean}
   */
  get isSingle () {
    return this.count === 1
  }

  /**
   * Indicates that the action will be executed on a batch of items
   * @type {Boolean}
   */
  get isBatch () {
    return this.count > 1
  }

  /**
   * Indicates that the action can be executed on a batch of items
   * @type {Boolean}
   */
  get allowBatch () {
    return Boolean(this.data?.allowBatch)
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return this.data?.label
  }

  /**
   * Action tooltip
   * @type {String}
   */
  get tooltip () {
    return null
  }

  /**
   * Indicates a potentially destructive action
   * @type {String}
   */
  get warning () {
    return false
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return this.data?.icon
  }

  /**
   * Action icon color
   * @type {String}
   */
  get color () {
    return this.data?.color || 'indigo-5'
  }

  /**
   * If specified, the user will have to confirm the action
   * @type {String}
   */
  get confirmation () {
    return this.data?.confirmation
  }

  /**
   * If specified, the message will be displayed after executing the action
   * @type {String}
   */
  get message () {
    return this.data?.message
  }

  /**
   * If `true`, all notifications during executing the action will be suppressed
   * @type {Boolean}
   */
  get silent () {
    return this.data?.silent
  }

  /**
   * Use this to indicate that action requires refresh
   * of the plan stage area after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return Boolean(this.data?.requiresRefresh)
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return Boolean(this.data?.preserveSelection)
  }

  /**
   * Executes the action.
   * Implement in descendant classes.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Number} point Item point to act on
   * @param {Point} position Position at which the action should be executed
   * @returns {any} Action results
   */
  // eslint-disable-next-line no-unused-vars
  execute ({ renderer, items, point, position }) {
    throw new Error('Not implemented')
  }

  /**
   * Verifies whether the specified action can be undone.
   * Override in descendants to prevent specific actions from participating in UNDO history.
   * @param {Object} parameters Action parameters
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  canUndo (parameters) {
    return true
  }

  /**
   * Returns all items to take a snapshot of, in order to be able to undo the action.
   * Some actions impact other items, apart from those directly passed to the action.
   * For example, when deleting a repeater, all connectors leading to it will be deleted as well.
   * Therefore, action removing items should override this method, to return these additional items as well.
   * @param {PlanLayout} layout Plan layout
   * @param {Array[PlanItem]} items Items on which the action is executed
   * @returns {Array[PlanItem]} All items ultimately impacted by the action
   */
  getUndoItems (layout, items) {
    if (layout) {
      return items
    }
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
  */
  async undo ({ renderer, data }) {
    if (!renderer) throw new Error('Renderer is required')
    if (!data) throw new Error('Action history is required')
  }

  /**
   * Redoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
  */
  async redo ({ renderer, data }) {
    if (!renderer) throw new Error('Renderer is required')
    if (!data) throw new Error('Action history is required')
  }
}

