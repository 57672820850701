import { parseBoolean, Point, safeParseInt } from '@stellacontrol/utilities'
import { PlanItem, PlanItemType } from './plan-item'
import { PlanPort, PlanPortType } from './plan-port'
import { PlanLineStyle, PlanArrowStyle } from '../styles'

/**
 * Connector linking to two items
 */
export class PlanConnector extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Connector
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      // Connectors don't have a position, they're defined by their turns
      x: undefined,
      y: undefined,
      z: undefined,
      // Connector style
      lineStyle: new PlanLineStyle({
        width: 4,
        color: 'blue'
      }),
      // Arrow style
      arrowStyle: PlanArrowStyle.None,
      // No smart routing yet
      smartRouting: false,
      // If specified, line points are aligned if moved by margin smaller than the specified here
      alignThreshold: 4
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.start = this.customize(this.start, PlanPort, new PlanPort({ type: PlanPortType.ConnectorEnd }))
    this.end = this.customize(this.end, PlanPort, new PlanPort({ type: PlanPortType.ConnectorEnd }))
    this.start.isStart = true
    this.end.isEnd = true
    this.turns = this.castArray(this.turns, Point, [])
    if (this.showOnCrossSection) {
      this.crossSection = {
        ...(this.crossSection || {}),
        turns: this.castArray(this.crossSection?.turns, Point, [])
      }
    }
    this.arrowStyle = this.customize(this.arrowStyle, PlanArrowStyle, defaults.arrowStyle)
    this.smartRouting = this.smartRouting != null ? parseBoolean(this.smartRouting) : defaults.smartRouting
    this.alignThreshold = safeParseInt(this.alignThreshold, defaults.alignThreshold)
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Delete runtime data
    delete result.item
    delete result.notAllowed
    delete result.wantsOwnRiser

    // Delete positions, as connectors are positioned dynamically
    delete result.x
    delete result.y
    delete result.z

    // Delete empty turns
    if (result.turns && result.turns.length === 0) {
      delete result.turns
    }
    if (result.crossSection) {
      if (result.crossSection.turns && result.crossSection.turns.length === 0) {
        delete result.crossSection.turns
      }
    }

    // Delete default styles
    if (this.arrowStyle?.sameAs(this.defaults.arrowStyle)) {
      delete result.arrowStyle
    }

    return result
  }

  /**
   * Connector start
   * @type {PlanPort}
   */
  start

  /**
   * Connector end
   * @type {PlanPort}
   */
  end

  /**
   * Identifier of a riser through which the connector passes.
   * Specified only when connector is a cross-floor connector
   * @type {String}
   */
  riser

  /**
   * If specified, it indicates that this connector represents a cross-floor connection
   * leading from an item on the floor into a riser, which is further connected to another floor,
   * and it contains the identifier of that cross-floor connection
   * @type {String}
   */
  partOf

  /**
   * If specified, it indicates that this connector represents a cross-floor connection
   * leading from an item on the floor into a riser, which is further connected to another floor.
   * @type {String}
   */
  goesIntoRiser

  /**
   * Indicates that a connector needs its own riser, not a shared one
   * @type {Boolean}
   * @description RUNTIME
   */
  wantsOwnRiser

  /**
   * Checks whether the data of the item is valid.
   * Invalid items will be discarded on load, to prevent the application from crashing
   * @type {Boolean}
   */
  get isValid () {
    const { inProgress, start, end } = this
    if (inProgress) {
      // When connector is being drawn, leave it as-is
      return true
    } else {
      const hasBothEnds = Boolean(start && end && start.item && end.item)
      return hasBothEnds
    }
  }

  /**
   * Indicates whether the connector crosses multiple floors
   * @type {Boolean}
   */
  get isCrossFloor () {
    const { isValid, partOf, start, end } = this
    return isValid && !partOf && start?.item && end?.item && start.item.floorId !== end.item.floorId
  }

  /**
   * Checks whether the connector is entering or leaving
   * the specified floor
   * @returns {Boolean}
   */
  toFloor (floor) {
    const { isValid, start, end } = this
    return floor && isValid && start?.item && end?.item && (start.item.floorId === floor.id || end.item.floorId === floor.id)
  }

  /**
   * Returns points making up the shape,
   * if {@link isPointBased} shape
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]}
   */
  getPoints (isCrossSection) {
    const turns = isCrossSection ? this.crossSection.turns : this.turns
    const start = this.start?.toPoint()
    const end = this.end?.toPoint()
    return [
      start,
      ...turns || [],
      end,
    ].filter(p => p)
  }

  /**
   * Indicates whether the connector is not allowed to connect to the selected end
   * @type {Boolean}
   * @description RUNTIME
   */
  notAllowed

  /**
   * Indicates whether smart routing should be applied
   * @type {Boolean}
   */
  smartRouting

  /**
   * If true, line points are aligned if moved by margin smaller than the specified here
   * @type {Number}
   */
  alignThreshold

  /**
   * Optional connector turning points
   * between the {@link start} and {@link end} points.
   * Empty, if connector is a straight line.
   * @type {Array[Point]}
   */
  turns

  /**
   * Checks whether the connector has any turns
   * @param {Boolean} isCrossSection Indicates whether we're talking about cross-section turns or floor plan turns
   * @returns {Boolean}
   */
  hasTurns (isCrossSection) {
    if (isCrossSection == null) throw new Error('Parameter isCrossSection must be explicitly specified')
    const turns = isCrossSection
      ? this.crossSection.turns || []
      : this.turns || []
    return turns.length > 0
  }

  /**
   * Returns the list of turn points of the connector
   * @param {Boolean} isCrossSection Indicates whether we're talking about cross-section turns or floor plan turns
   * @returns {Array[Point]} Connector turns
   */
  getTurns (isCrossSection) {
    if (isCrossSection == null) throw new Error('Parameter isCrossSection must be explicitly specified')
    return isCrossSection
      ? this.crossSection.turns || []
      : this.turns || []
  }

  /**
   * Assigns new list of turn points
   * @param {Array[Point]} turns Turns to assign
   * @param {Boolean} isCrossSection Indicates whether we're talking about cross-section turns or floor plan turns
   */
  setTurns (turns, isCrossSection) {
    if (isCrossSection == null) throw new Error('Parameter isCrossSection must be explicitly specified')

    turns = (turns || [])
      .filter(t => t)
      .map(t => Point.from(t))

    if (isCrossSection) {
      this.crossSection.turns = turns
    } else {
      this.turns = turns
    }
  }

  /**
   * Returns the first inner point of the connector
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point}
   */
  getFirstPoint (isCrossSection) {
    const turns = isCrossSection ? this.crossSection.turns : this.turns
    return turns ? turns[0] : null
  }

  /**
   * Returns the first of the turns
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point}
  */
  getFirstTurn (isCrossSection) {
    const turns = isCrossSection ? this.crossSection.turns : this.turns
    return turns ? turns[0] : null
  }

  /**
   * Returns the last of the turns
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point}
   */
  getLastTurn (isCrossSection) {
    const turns = isCrossSection ? this.crossSection.turns : this.turns
    return turns ? turns[turns.length - 1] : null
  }

  /**
  * Line arrow style
  * @type {PlanArrowStyle}
  */
  arrowStyle

  /**
  * Indicates that connector has one or more arrows
  * @type {Boolean}
  */
  get hasArrows () {
    return this.arrowStyle && !this.arrowStyle.isEmpty
  }

  /**
   * Indicates that current item is connected to the specified item.
   * Override in descendants such as connectors, cables etc.
   * @param {PlanItem} item
   * @param {PlanPortType} portType Optional port of the item, to which the connector needs to be connected
   * @returns {Boolean}
   */
  isConnectedTo (item, portType) {
    if (item) {
      const { start, end } = this

      if (start?.itemId === item.id) {
        return !portType || start?.type === portType
      }

      if (end?.itemId === item.id) {
        return !portType || end?.type === portType
      }
    }
    return false
  }

  /**
   * If connector is connected to the specified at one of its ends, returns the item at the other end
   * @param {PlanItem} item
   * @returns {PlanItem}
   */
  getOtherItem (item) {
    if (item) {
      const { start, end } = this
      return (start?.itemId === item.id)
        ? end.item
        : (end?.itemId === item.id ? start.item : undefined)
    }
  }

  /**
   * Returns the connection point which is connected to the specified item.
   * @param {PlanItem} item
   * @returns {PlanPort}
   */
  getConnectionPointOf (item) {
    const { start, end } = this
    if (item) {
      return start?.itemId === item.id
        ? start
        : end?.itemId === item.id
          ? end
          : undefined
    }
  }

  /**
   * Returns the specified turn of the connector
   * @param {Number} index Point index
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point}
   */
  getPoint (index, isCrossSection) {
    let turns = isCrossSection ? this.crossSection.turns : this.turns
    if (turns) {
      return turns[index - 1]
    }
  }

  /**
   * Adds a turn to the connector.
   * @param {Point} point Point to add
   * @param {Number} index Point index. Notice that point index is different than turn index,
   * as it includes the start and end point of the connector, which aren't stored in turns!
   * Thus point at index `1` is equivalent to turn at index `0`.
   * @param {Point} align If true, points are aligned to each other to ensure proper straight angles when movements are minuscule
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]} Connector turns
   */
  addPoint (point, index, align, isCrossSection) {
    let turns = isCrossSection ? this.crossSection.turns : this.turns

    // Add a turn at the start of turns
    if (index === 0) {
      turns = [point, ...turns]
    }

    // Add a turn at the end of turns
    if (index == null) {
      turns = [...turns, point]
    }

    // Add a turn in the middle
    if (index >= 1 && (index - 2) < turns.length) {
      // Align the point, if position on X and/or Y axis is only slightly different,
      // to ensure that we have nice straight lines
      if (align) {
        // Include the connector start and end, not just turns!
        const { alignThreshold, start, end } = this
        const previous = index === 1
          ? start.point
          : turns[index - 2]
        const next = turns.length < index
          ? end.point
          : turns[index - 1]
        this.alignPoint(previous, point, next, alignThreshold)
      }

      // Insert the point between the existing turns
      turns = [...turns.slice(0, index - 1), point, ...turns.slice(index - 1)]
    }

    // Store the modified turns
    if (isCrossSection) {
      this.crossSection.turns = turns
    } else {
      this.turns = turns
    }

    return isCrossSection ? this.crossSection.turns : this.turns
  }

  /**
   * Updates a turn to the connector.
   * @param {Point} point Point to update
   * @param {Number} index Point index. Notice that point index is different than turn index,
   * as it includes the start and end point of the connector, which aren't stored in turns!
   * Thus point at index `1` is equivalent to turn at index `0`.
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]} Connector turns
   */
  updatePoint (point, index, isCrossSection) {
    // Update the turn
    let turns = isCrossSection ? this.crossSection.turns : this.turns
    if (point && index > 0 && index <= turns.length) {
      turns[index - 1] = point
    }

    // Store the modified turns
    if (isCrossSection) {
      this.crossSection.turns = turns
    } else {
      this.turns = turns
    }

    return isCrossSection ? this.crossSection.turns : this.turns
  }

  /**
   * Removes a turn from the connector.
   * @param {Number} index Index at which to remove the point
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]} Connector turns
   */
  removePoint (index, isCrossSection) {
    let turns = isCrossSection ? this.crossSection.turns : this.turns

    if (index >= 1 && (index - 2) < turns.length) {
      turns = [...turns.slice(0, index - 1), ...turns.slice(index)]
      if (isCrossSection) {
        this.crossSection.turns = turns
      } else {
        this.turns = turns
      }
    }

    return isCrossSection ? this.crossSection.turns : this.turns
  }

  /**
   * Removes the last turn from the connector.
   * @param {Number} index Index at which to remove the point
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point} Removed turn
   */
  removeLastPoint (isCrossSection) {
    let turns = isCrossSection ? this.crossSection.turns : this.turns
    const count = turns.length
    const lastTurn = turns[count - 1]

    turns = turns.slice(0, count - 1)
    if (isCrossSection) {
      this.crossSection.turns = turns
    } else {
      this.turns = turns
    }

    return lastTurn
  }

  /**
   * Moves line point to the specified new coordinates
   * @param {Number} index Point index
   * @param {Point} position Position to which the point was moved
   * @param {Point} align If true, points are aligned to each other to ensure proper straight angles when movements are minuscule
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Point} Moved point
   */
  movePoint (index, position, align, isCrossSection) {
    let turns = isCrossSection ? this.crossSection.turns : this.turns
    const point = turns[index - 1]
    if (!point) return

    const { x, y } = position.round()
    point.x = x
    point.y = y

    // If point was sticky, unstick it, now that user decided to move it elsewhere
    delete point.isSticky

    if (align) {
      // Include the connector start and end, not just turns!
      const { alignThreshold, start, end } = this
      const previous = index === 1
        ? start.point
        : turns[index - 2]
      const next = turns.length - 1 < index
        ? end.point
        : turns[index]
      this.alignPoint(previous, point, next, alignThreshold)
    }

    return this.getPoint(index, isCrossSection)
  }

  /**
   * Called when connector has been moved to the specified position.
   * Connectors cannot be moved by dragging, but they can be moved when selected together with other items, using keyboard
   * Translate the connector turns accordingly.
   * @param {Point} position Position to which the item was moved
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @param {Point} delta Position change
   * @returns {PlanItem}
   */
  moveTo (position, isCrossSection, delta) {
    super.moveTo(position, isCrossSection, delta)

    if (position && delta) {
      const { x, y } = Point.from(delta).round()
      this
        .getTurns(isCrossSection)
        .forEach(turn => turn.moveBy({ x, y }))
    }

    return this
  }

  /**
   * Moves the item by the specified delta.
   * @param {Point} delta Delta to move the item by
   * @param {Boolean} isCrossSection If true, the coordinates on cross section have been changed,
   * otherwise just the ordinary coordinates on the floor
   * @returns {PlanItem}
   */
  moveBy (delta, isCrossSection) {
    super.moveBy(delta, isCrossSection)

    if (delta) {
      const { x, y } = Point.from(delta).round()
      this
        .getTurns(isCrossSection)
        .forEach(turn => turn.moveBy({ x, y }))
    }

    return this
  }

  /**
   * Checks whether the connector is starting at the specified item.
   * Notice that connectors can be drawn either way, so the returned result
   * can be either the {@link start} or {@link end} of the connector!
   * @param {PlanItemType} type We check whether either end of the cable connects to an item of the specified type.
   * @param {Function<PlanItem, PlanItem, PlanPort, PlanPort, Boolean>} condition Optional, additional condition to check
   * on the connector ends. The predicate receives the logical start and end of the connectors, and the connected ports.
   * @returns Object containing the details of the connection: `{ from, fromPort, to, toPort }`
   */
  startsAt (type, condition) {
    const { start, end } = this

    if (type && start && start.item && end && end.item) {
      const startsAt = start.item.is(type)
      const endsAt = end.item.is(type)

      // The connector indeed starts at the specified item
      if (startsAt && start.isOut) {
        // Check other required conditions
        const matches = (condition ? condition(start.item, end.item, start, end) : true)
        if (matches) {
          return {
            from: start.item,
            fromPort: start,
            to: end.item,
            toPort: end
          }
        }
      }

      // The connector is reversed, but still matches
      // End port should be out
      if (endsAt && end.isOut) {
        // Check other required conditions
        const matches = (condition ? condition(end.item, start.item, end, start) : true)
        if (matches) {
          return {
            from: end.item,
            fromPort: end,
            to: start.item,
            toPort: start
          }
        }
      }
    }
  }

  /**
   * Checks whether the connector is ending at the specified item.
   * Notice that connectors can be drawn either way, so the returned result
   * can be either the {@link start} or {@link end} of the connector!
   * @param {PlanItemType} type We check whether either end of the cable connects to an item of the specified type.
   * @param {Function<PlanItem, PlanItem, PlanPort, PlanPort, Boolean>} condition Optional, additional condition to check
   * on the connector ends. The predicate receives the logical start and end of the connectors, and the connected ports.
   * @returns Object containing the details of the connection: `{ from, fromPort, to, toPort }`
   */
  endsAt (type, condition) {
    const { start, end } = this

    if (type && start && start.item && end && end.item) {
      const endsAt = end.item.is(type)
      const startsAt = start.item.is(type)

      // The connector indeed ends at the specified item
      // End port should be input
      if (endsAt && end.isIn) {
        return (condition ? condition(end.item, start.item, end, start) : true)
          ? {
            from: start.item,
            fromPort: start,
            to: end.item,
            toPort: end
          }
          : null
      } else if (startsAt && start.isIn) {
        // The connector is reversed!
        // Start port should be input
        return (condition ? condition(start.item, end.item, start, end) : true)
          ? {
            from: end.item,
            fromPort: end,
            to: start.item,
            toPort: start
          }
          : null
      }
    }
  }

  /**
   * Checks whether the cable connects to the specified item on one of ends
   * @param {PlanItemType} type We check whether either end of the cable connects to an item of the specified type.
   * @param {Function<PlanItem, PlanItem, PlanPort, PlanPort, Boolean>} condition Optional, additional condition to check
   * on the connector ends. The predicate receives the logical start and end of the connectors, and the connected ports.
   * @returns Object containing the details of the connection: `{ from, fromPort, to, toPort }`
   */
  connectsTo (type, condition) {
    return this.startsAt(type, condition) || this.endsAt(type, condition)
  }

  /**
   * Checks whether a new connector being drawn
   * is allowed to connect to the specified target
   * @param {PlanItem} target Item to connect to
   * @param {PlanPort} port Port on the item to connect to
   * @param {PlanLayout} layout Entire plan layout
   * @param {PlanHierarchy} hierarchy Equipment hierarchy
   */
  checkIfAllowed (target, port, layout, hierarchy) {
    if (!(target && port && layout && hierarchy)) return false

    // Cannot connect to itself
    if (target.id === this.id) return false

    // The port to which we're connecting, must not have any other already connected to it
    if (port.item) return false

    // Checks whether connector source and target meet the specified conditions.
    // Mind that connector can be drawn both ways, so we check both directions!
    const connects = (sourceCondition, targetCondition) => {
      const { start } = this
      return start && (
        (sourceCondition(start.item, start) && targetCondition(target, port)) ||
        (sourceCondition(target, port) && targetCondition(start.item, start)))
    }

    let allowed = true

    // Must be one of the following connections:

    // 1. External antenna into repeater input port
    allowed = allowed || connects(
      (from, port) => from.isAntenna && port.isIn,
      (to, port) => to.isRepeater && port.isIn)

    // 2. Repeater or amplifier output port to internal antenna
    allowed = allowed || connects(
      (from, port) => from.isDevice && port.isOut,
      (to, port) => to.isAntenna && port.isIn)

    // 3. Repeater output port to amplifier input port
    allowed = allowed || connects(
      (from, port) => from.isRepeater && port.isOut,
      (to, port) => to.isAmplifier && port.isIn)

    // 4. Repeater output port to splitter input port
    allowed = allowed || connects(
      (from, port) => from.isRepeater && port.isOut,
      (to, port) => to.isSplitter && port.isIn)

    // 5. Splitter output port to amplifier
    allowed = allowed || connects(
      (from, port) => from.isSplitter && port.isOut,
      (to, port) => to.isAmplifier && port.isIn)

    // 6. Splitter output port to internal antenna
    allowed = allowed || connects(
      (from, port) => from.isSplitter && port.isOut,
      (to, port) => to.isAntenna && port.isIn)

    // 7. Only `yagi`, `panel` and `omni` allowed as internal antenna
    // TODO

    // 8. Only `laser`, `yagi` and `panel` are allowed as external antenna
    // TODO

    this.notAllowed = !allowed
    return this.notAllowed
  }

  /**
   * Checks whether the newly created connector can link
   * to the item by clicking anywhere on the entire shape.
   * This is the case with shapes such as antennas which have only one port to choose from,
   * but also other shapes, such as connecting from repeater into lineamp,
   * where the only viable port is the external port on the lineamp.
   * @param {PlanItem} target Item at which the connector would end
   * @returns {Boolean}
   */
  canConnectToEntireShape (target) {
    const { start: { isInternal, item: { id, isDevice, isRepeater } = {} } } = this
    if (!(id && target)) return false

    if (target.isAntenna) return true
    if (isRepeater && isInternal && target.isLineamp) return true
    if (isDevice && isInternal && target.isSplitter) return true

    return false
  }
}
