import { Confirmation } from '@stellacontrol/client-utilities'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Toggles walls and yards lock
 */
export class LockWallsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.LockWalls
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Locks/unlocks walls and yards'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isLocked If true, walls and yards on radiation layer are locked for any modifications
   */
  async execute ({ renderer, isLocked } = {}) {
    if (renderer) {
      const { radiationLayer, floor } = renderer
      floor.lockWalls(isLocked)

      renderer.reset()
      radiationLayer.refresh()
    }
  }
}

/**
 * Clears walls and yards
 */
export class ClearWallsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ClearWalls
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Clears walls and yards '
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      const { layout, radiationLayer, floor } = renderer
      renderer.stopAddingItem()
      const items = [...floor.walls, ...floor.yards]
      for (const item of items) {
        layout.removeItem(item)
        renderer.notifyLayers(layer => layer.itemRemoved(item))
      }
      radiationLayer.refresh()
      renderer.changed({ action: this })
    }
  }
}

/**
 * Sets the global radiation strength for all antennae
 */
export class SetRadiationStrengthAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetRadiationStrength
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Sets the global radiation strength for all antennas'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Number} strength Radiation strength for all antennae on the current floor
   * @param {Boolean} allFloors If true, the new radiation strength will be applied to other floors as well
   */
  async execute ({ renderer, strength, allFloors } = {}) {
    if (renderer) {
      const { radiationLayer, floor, layout } = renderer
      floor.radiation.strength = strength

      // Check whether to apply the antenna strength to all other floors
      let applyToAllFloors = !layout.allowAntennaStrengthPerFloor
      if (!applyToAllFloors) {
        if (renderer.layout.floorCount > 1 && allFloors) {
          applyToAllFloors = await Confirmation.ask(({ title: 'Confirmation', message: 'Apply the new antenna strength to the other floors as well?' }))
        }
      }

      if (applyToAllFloors) {
        for (const floor of renderer.layout.floors) {
          floor.radiation.strength = strength
        }
      }

      radiationLayer.refresh()
      renderer.changed({ action: this })
    }
  }
}


/**
 * Clears the transparent colors for the background image
 */
export class ClearBackgroundTransparencyAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ClearBackgroundTransparency
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Clear transparency'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      await renderer.floor.background.clearTransparentColors()
      // await renderer.backgroundLayer.refresh()
      await renderer.backgroundLayer.applyImageTransparency()
      await renderer.cancelEditing()

      renderer.changed()
    }
  }
}

/**
 * Starts/ends selection of transparent colors for the background image
 */
export class SetBackgroundTransparencyAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetBackgroundTransparency
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Set transparency'
  }

  /**
   * Use this to indicate that action requires refresh
   * of the entire plan stage after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {String} color Color to be considered as transparent color of the background image.
   * If not specifed, we just toggle setting mode on/off
   * @param {Boolean} remove If `true`, the {@link color} will be removed, not added
   */
  async execute ({ renderer, color, remove } = {}) {
    if (renderer) {
      if (color) {
        const modified = remove
          ? renderer.floor.background.removeTransparentColors(color)
          : renderer.floor.background.addTransparentColors(color)
        if (modified) {
          await renderer.backgroundLayer.applyImageTransparency()
        }
      } else {
        if (!renderer.isSettingTransparentColors) {
          await renderer.cancelEditing()
        }
        await renderer.floor.background.clearTransparentColors()
        await renderer.setTransparentColors(!renderer.isSettingTransparentColors)
      }
    }
  }

  /**
   * Verifies whether the specified action can be undone.
   * Override in descendants to prevent specific actions from participating in UNDO history.
   * @param {Object} parameters Action parameters
   * @returns {Boolean}
   */
  canUndo (parameters) {
    return parameters?.color && !parameters?.remove
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
   */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })

    const { color, remove } = data.parameters
    if (color) {
      if (remove) {
        // The color was removed, add it
        await renderer.floor.background.addTransparentColors(color)
        await renderer.backgroundLayer.applyImageTransparency()
      } else {
        // The color was added, remove it
        await renderer.floor.background.removeTransparentColors(color)
        await renderer.backgroundLayer.applyImageTransparency()
      }
    }
  }
}

/**
 * Cancels editing transparent colors for the background image,
 * reverts back to defaults
 */
export class CancelBackgroundTransparencyAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.CancelBackgroundTransparency
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Cancel editing transparency'
  }

  /**
   * Use this to indicate that action requires refresh
   * of the entire plan stage after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      await renderer.floor.background.clearTransparentColors()
      await renderer.backgroundLayer.applyImageTransparency()
      renderer.changed()
    }
  }
}
