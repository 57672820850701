import { PlanAction, PlanActions } from './plan-action'

/**
 * Selects the specified items
 */
export class SelectItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SelectItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Select items'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'highlight_alt'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    if (renderer && items) {
      if (items.length > 1) {
        renderer.selectItems({ items })
      } else {
        const item = items[0]
        const position = item.getCoordinates(renderer.isCrossSection)
        renderer.selectItem({ item, position })
      }
    }
  }

  /**
   * Verifies whether the specified action can be undone.
   * Override in descendants to prevent specific actions from participating in UNDO history.
   * @param {Object} parameters Action parameters
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  canUndo (parameters) {
    return false
  }
}

/**
 * Selects all items on the plan
 */
export class SelectAllItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SelectAllItems
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Select all'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'select_all'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanLayers} layer If specified, only the items on the specified layer will be selected
   */
  async execute ({ renderer, layer } = {}) {
    if (renderer) {
      const items = renderer
        .selectableItems
        .filter(item => layer ? item.isOnLayer(layer) : true)
      renderer.selectItems({ items })
    }
  }

  /**
   * Verifies whether the specified action can be undone.
   * Override in descendants to prevent specific actions from participating in UNDO history.
   * @param {Object} parameters Action parameters
   * @returns {Boolean}
   */
  // eslint-disable-next-line no-unused-vars
  canUndo (parameters) {
    return false
  }
}
